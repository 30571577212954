ul {
  list-style: none; }

.logo {
  min-width: 18rem; }

nav {
  font-weight: 400;
  margin-top: 3rem;
  padding: 0 2rem; }
  nav button {
    border-radius: 0;
    background-color: whitesmoke;
    border-color: #b48997;
    color: #515356;
    font-size: 85%;
    font-weight: inherit;
    padding: 0 1.5rem; }
  nav label {
    font-size: 80%;
    color: #515356;
    font-weight: inherit; }
  nav li {
    text-align: center;
    width: 100%; }
  @media (min-width: 64em) {
    nav li {
      width: initial; } }
  nav a {
    text-transform: uppercase;
    white-space: nowrap;
    color: #515356; }
    nav a:not(.logo-link).selected, nav a:not(.logo-link):hover {
      color: #515356;
      border-bottom: 4px solid #6a1330; }
